import { Icon } from "@chakra-ui/react";

export default function AddCollectionIcon(props) {
  return (
    <Icon width="56px" height="56px" viewBox="0 0 56 56" {...props}>
      <path
        opacity="0.4"
        d="M8.66981 9.0197H32.9904L36.8208 5.18939C37.7501 4.25982 38.9862 3.74783 40.301 3.74783H47.6528V1.64062C47.6528 0.73456 46.9183 0 46.0122 0H1.64062C0.734561 0 0 0.73456 0 1.64062V28.3274L3.89483 12.748C4.44357 10.5528 6.40706 9.0197 8.66981 9.0197Z"
        fill="#7AE7FF"
      />
      <path
        d="M43.8155 28.3499C46.2966 28.3499 48.6425 28.9381 50.7227 29.9808L55.9509 9.06758C56.0735 8.57747 55.9634 8.05816 55.6524 7.66004C55.3415 7.2618 54.8645 7.02905 54.3593 7.02905H40.301C39.8658 7.02905 39.4485 7.20197 39.141 7.50965L34.3496 12.301H8.66985C7.91702 12.301 7.26077 12.8134 7.07823 13.5437L0.0490415 41.6603C-0.0735675 42.1504 0.0365728 42.6697 0.347525 43.0678C0.658477 43.466 1.13546 43.6988 1.64066 43.6988H28.3515C28.4145 35.2246 35.3269 28.3499 43.8155 28.3499Z"
        fill="#7AE7FF"
      />
      <path
        d="M43.8156 31.6311C37.0971 31.6311 31.6313 37.097 31.6313 43.8154C32.2438 59.9575 55.3899 59.9529 56 43.8153C55.9999 37.0969 50.534 31.6311 43.8156 31.6311ZM47.3301 45.4561H45.4562V47.33C45.4562 48.236 44.7216 48.9706 43.8156 48.9706C42.9095 48.9706 42.175 48.236 42.175 47.33V45.4561H40.301C39.395 45.4561 38.6604 44.7215 38.6604 43.8154C38.6604 42.9094 39.395 42.1748 40.301 42.1748H42.175V40.3008C42.175 39.3947 42.9095 38.6602 43.8156 38.6602C44.7216 38.6602 45.4562 39.3947 45.4562 40.3008V42.1747H47.3302C49.5042 42.2552 49.5034 45.376 47.3301 45.4561Z"
        fill="#7AE7FF"
      />
    </Icon>
  );
}
