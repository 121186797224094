import { chakra } from "@chakra-ui/react";
import * as React from "react";

export const ArtZeroLogo = ({ display, height = 28, width = 194 }) => {
  // const { iconColor = "currentColor", ...rest } = props;
  // const color = useToken("colors", iconColor);
  return (
    <chakra.svg
      display={display}
      width={width}
      height={height}
      viewBox="0 0 2490 301"
      fill="none"
    >
      <path
        d="M1395.14 238.996V301H1151.73L1213.74 238.996H1395.14Z"
        fill="white"
      />
      <path
        d="M1771.36 0.819946V28.1836L1737.95 61.4914H1590.27L1527.85 0.819946H1771.36Z"
        fill="white"
      />
      <path
        d="M681.221 62.1062L576.174 168.486L681.221 272.714V300.18H614.093L495.005 180.989H460.365V119.088H534.667L592.264 61.4913H454.728L393.339 0H681.221V62.1062Z"
        fill="white"
      />
      <path
        d="M438.228 74.302V300.18H376.224V12.2983L438.228 74.302Z"
        fill="white"
      />
      <path
        d="M2113.46 62.1062L2008.41 168.486L2113.46 272.714V300.18H2046.33L1927.24 180.989H1892.6V119.088H1966.9L2024.5 61.4913L1886.96 61.3888L1825.57 0H2113.46V62.1062Z"
        fill="white"
      />
      <path
        d="M1870.36 74.302V300.18H1808.46V12.2983L1870.36 74.302Z"
        fill="white"
      />
      <path
        d="M1017.78 0.102478V27.4661L983.759 61.4913L898.696 61.3888V300.283H836.692V61.3888H717.604V0.102478H1017.78Z"
        fill="white"
      />
      <path
        d="M1394.21 0.819946V28.1836L1121.5 301H1054.37V273.534L1266.52 61.4914L1054.37 61.3889V0.819946H1394.21Z"
        fill="white"
      />
      <path
        d="M1771.36 273.534V301H1431.42V273.534L1554.09 150.859L1431.42 28.1836V0.819946H1498.54L1617.63 119.806H1731.6V181.809H1617.63L1560.55 238.996H1736.62L1771.36 273.534Z"
        fill="white"
      />
      <path
        d="M2477.49 0.102478L2415.99 61.4913L2211.84 61.3888V238.894L2149.94 300.283V34.7426L2184.58 0.102478H2477.49Z"
        fill="white"
      />
      <path
        d="M2489.78 12.9131V265.642L2455.25 300.283H2176.49L2236.03 238.791L2427.78 238.894V76.2492L2489.78 12.9131Z"
        fill="white"
      />
      <path
        d="M339.842 0V300.283H277.838V261.646H140.303L200.974 198.514H277.838V89.3674L67.128 300.283H0V272.816L272.816 0H339.842Z"
        fill="white"
      />
    </chakra.svg>
  );
};
