import { Icon } from "@chakra-ui/react";

export default function BaseURIIcon({
  color = "#7ae7ff",
  width = "17px",
  height = "16px",
}) {
  return (
    <Icon
      className="base-uri-icon"
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill={color}
    >
      <path d="M1.297 7.344h16.406a.328.328 0 0 1 .328.328v3.281a.328.328 0 0 1-.328.328H1.297a.328.328 0 0 1-.328-.328V7.672a.328.328 0 0 1 .328-.328Zm-.328 7.218a1.313 1.313 0 0 0 1.312 1.313H16.72a1.313 1.313 0 0 0 1.312-1.313v-1.64a.328.328 0 0 0-.328-.328H1.297a.328.328 0 0 0-.328.328v1.64Zm17.062-10.5v1.641a.328.328 0 0 1-.328.328H1.297a.328.328 0 0 1-.328-.328v-1.64A1.313 1.313 0 0 1 2.28 2.75h3.281v-.656A1.96 1.96 0 0 1 7.532.125h3.937a1.96 1.96 0 0 1 1.969 1.969v.656h3.28a1.312 1.312 0 0 1 1.313 1.313Zm-5.906-1.968a.656.656 0 0 0-.656-.656H7.53a.656.656 0 0 0-.656.656v.656h5.25v-.656Z" />
    </Icon>
  );
}
